<template>
  <div>
    <label v-html="$t('$app.md_editor.label')" />
    <textarea ref="mdEditor" />
  </div>
</template>
<script>
import CodeMirror from 'codemirror'
window.CodeMirror = CodeMirror

const opts = {
  placeholder: 'Ответ',
  forceSync: true,
  spellChecker: false,
  status: false,
  toolbar: [
    'bold', 'italic', 'strikethrough',
    'code', 'quote', 'ordered-list', 'unordered-list',
    'clean-block', 'link'
  ]
}

export default ({
  name: 'MarkdownEditor',
  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      simplemde: null
    }
  },

  async mounted () {
    const element = this.$refs.mdEditor
    const { default: SimpleMDE } = await import('simplemde')
    this.simplemde = new SimpleMDE(Object.assign({}, { element }, opts))

    this.simplemde.codemirror.on('change', () => {
      this.$emit('input', this.simplemde.value().trim())
    })
  },

  beforeDestroy () {
    this.simplemde = null
  }
})
</script>

<style lang="scss">
  @import url('~simplemde/src/css/simplemde.css');
  @import url('~codemirror/lib/codemirror.css');

  .CodeMirror {
    width: 100%;
    height: 8rem;
    min-height: 6rem;
    resize: vertical;
    padding: 0.25rem;
    z-index: auto;

    .CodeMirror-scroll {
      min-height: inherit;
      max-height: 96vh;
    }
  }
</style>
