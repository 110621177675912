<template>
  <Component
    :is="tag"
    :class="classes"
  >
    <slot />
  </Component>
</template>

<script>
export default {
  name: 'UComment',

  props: {
    tag: {
      type: String,
      default: 'p'
    }
  },

  computed: {
    classes () {
      return {
        'u-comment': true
      }
    }
  }
}
</script>

<style lang="scss">
  .u-comment.u-comment {
    margin: 0;
    padding: 0.5rem;
    border-radius: $border-radius-root;
    border: $border;
    color: map-deep-get($material-light, 'text', 'primary');
    font-size: 0.875rem;
    background-color: rgba(black, 0.06);
  }
</style>
