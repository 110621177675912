
export interface IAnswerSettings {
  question_type: string
  answer_key: string
  answer_value: string
  user_answer_id?: number,
  user_answer_key?: string
  user_answer_value?: string
  is_correct?: boolean
  valid?: boolean
}

export interface IAnswer {
  session_id: number
  session_uuid: string
  session_title: string
  step_id: number
  step_uuid: string
  step_title: string
  quiz_id: number
  question_id: number
  answer_id: number
  grade: number
  answer: string
  settings: IAnswerSettings
  comments?: string
}

export class AnswerSettings {
  type: string
  key: string
  value: string
  userAnswerId: number
  userAnswerKey: string
  userAnswerValue: string
  isCorrect: boolean

  static QUESTION_TYPE_SIMPLE = 'simple'
  static QUESTION_TYPE_RANKING = 'ranking'
  static QUESTION_TYPE_MAPPING = 'mapping'
  static QUESTION_TYPE_GRADING = 'grading'
  static QUESTION_TYPE_TEXT = 'text'
  static DEFAULT_QUESTION_TYPE = AnswerSettings.QUESTION_TYPE_SIMPLE

  constructor (settings: IAnswerSettings) {
    this.type = settings.question_type || AnswerSettings.DEFAULT_QUESTION_TYPE
    this.key = settings.answer_key || ''
    this.value = settings.answer_value || ''
    this.userAnswerId = settings.user_answer_id || 0
    this.userAnswerKey = settings.user_answer_key || ''
    this.userAnswerValue = settings.user_answer_value || ''
    this.isCorrect = !!(settings.is_correct || settings.valid || false)
  }
}

export class Answer {
  id: number
  questionId: number
  answer: string
  grade: number
  comments: string
  settings: AnswerSettings

  constructor (answer: IAnswer) {
    this.id = answer.answer_id || 0
    this.questionId = answer.question_id || 0
    this.answer = answer.answer || ''
    this.grade = answer.grade || 0
    this.comments = answer.comments || ''
    this.settings = new AnswerSettings(answer.settings)
  }
}

export class QuestionAnswer extends Answer {
  _userAnswers: IAnswer[] = []

  constructor (answer: IAnswer, userAnswers = []) {
    super(answer)
    this._userAnswers = userAnswers
  }

  get isDefaultType () {
    return this.settings.type === AnswerSettings.DEFAULT_QUESTION_TYPE
  }

  get isSimpleType () {
    return this.settings.type === AnswerSettings.QUESTION_TYPE_SIMPLE
  }

  get isRankingType () {
    return this.settings.type === AnswerSettings.QUESTION_TYPE_RANKING
  }

  get isMappingType () {
    return this.settings.type === AnswerSettings.QUESTION_TYPE_MAPPING
  }

  get isGradingType () {
    return this.settings.type === AnswerSettings.QUESTION_TYPE_GRADING
  }

  get isTextType () {
    return this.settings.type === AnswerSettings.QUESTION_TYPE_TEXT
  }

  get isSelected () {
    return this._userAnswers.some(answer => answer.answer_id === this.id)
  }
}
