<template>
  <div
    v-if="!isReadonly"
    ref="answers"
    class="task__answers"
  >
    <template v-if="!isMultipleChoice">
      <p>{{ $t('$app.choose_one_answer.one') }}:</p>

      <VRadioGroup
        :value="value[0]"
        hide-details
        class="ma-0 pa-0"
        @change="$emit('input', [$event])"
      >
        <TrainingQuizAnswer
          v-for="(answer, idx) in answers"
          :key="answer.id"
          class="my-2"
          :answer="answer"
          :index="idx"
          :is-revealed="isRevealed"
          :is-readonly="isReadonly"
          is-simple-type
        >
          <VRadio
            :value="answer.id"
            color="success"
          >
            <template #label>
              <MarkdownContent
                class="answer__content"
                :content="answer.answer"
              />
            </template>
          </VRadio>
        </TrainingQuizAnswer>
      </VRadioGroup>
    </template>

    <template v-else>
      <p>{{ $t('question_may_have_several_answers.one') }}.</p>

      <TrainingQuizAnswer
        v-for="(answer, idx) in answers"
        :key="answer.id"
        :answer="answer"
        :index="idx"
        :is-revealed="isRevealed"
        :is-readonly="isReadonly"
        is-simple-type
      >
        <VCheckbox
          :input-value="value"
          :value="answer.id"
          hide-details
          color="success"
          @change="$emit('input', $event)"
        >
          <template #label>
            <MarkdownContent
              class="answer__content"
              :content="answer.answer"
            />
          </template>
        </VCheckbox>
      </TrainingQuizAnswer>
    </template>
  </div>

  <ul
    v-else
    class="task__answers"
  >
    <TrainingQuizAnswer
      v-for="(answer, idx) in answers"
      :key="answer.id"
      :answer="answer"
      :index="idx"
      :is-revealed="isRevealed"
      :is-readonly="isReadonly"
      :is-show-correct-answer="isShowCorrectAnswer"
      tag="li"
    />
  </ul>
</template>

<script>
import TrainingQuizAnswer from '@components/TrainingQuizAnswer.vue'
import MarkdownContent from '@/components/MarkdownContent.vue'

export default {
  name: 'TrainingQuizQuestionTypeSimple',

  components: {
    TrainingQuizAnswer,
    MarkdownContent
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    isMultipleChoice: {
      type: Boolean,
      default: false
    },

    isRevealed: {
      type: Boolean,
      default: false
    },

    isReadonly: {
      type: Boolean,
      default: false
    },

    isShowCorrectAnswer: {
      type: Boolean,
      default: false
    },

    answers: {
      type: Array,
      default: () => []
    },

    availableAnswers: {
      type: Array,
      default: () => []
    }
  },

  mounted () {
    const container = this.$refs.answers

    if (container) {
      container.querySelectorAll('.answer__body label').forEach(label => {
        label.classList.add('bvi-speech')
      })
    }
  }
}
</script>

<style lang="scss">
.bvi-active .answer__body label.bvi-speech {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.answer__content > p:last-child {
  margin-bottom: 0;
}
</style>
